<template>
  <v-main>
    <template v-if="viewDataIsLoaded">
      <v-app-bar
        fixed
        elevate-on-scroll
        dark
        color="primary"
      >
        <v-btn
          icon
          @click="goHome()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ eventGroup.egr_id == '' ? 'Ny eventgrupp' : 'Eventgrupp: ' + eventGroup.egr_str_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveEventGroup"
          >
            Spara
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-container>
        <v-row class="pt-16">
          <v-col class="pt-4 pb-16">
            <v-form ref="formEdit">
              <!--title-->
              <v-text-field
                label="Namn"
                v-model="eventGroup.egr_str_name"
                outlined
                dense
                :rules="[formRules.required]"
                validate-on-blur
                maxlength="25"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'
import { arrayMixin } from '../mixins/array'

export default {
  name: 'EventGroupEdit',
  components: {
    'app-snack-bar': SnackBar,
  },
  mixins: [generalMixin, urlMixin, dateMixin, arrayMixin],
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    viewDataIsLoaded: false,
    eventGroup: {}
  }),
  computed: {
    ...mapGetters(['agent']),
  },
  methods: {
    getData() {
      console.log('getData')
      if (this.loading) {
        return
      }
      this.loading = true
      let relUrl = '/admin/event-group-edit/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      axios.get(url)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    onData(data) {
      this.eventGroup = data.eventGroup
      //final
      this.viewDataIsLoaded = true
    },
    onSave() {
      this.goHome()
    },
    goHome() {
      //go to home with event tab active, like this
      this.$router.replace({ name: 'home', hash: '#events' })
    },
    saveEventGroup() {
      if (this.loading) {
        return
      }
      const formIsValid = this.$refs.formEdit.validate()
      if (!formIsValid) {
        snackBarBus.$emit('message', 'Formuläret är inte komplett ifyllt')
        return
      }
      this.loading = true
      const postData = {
        agent: this.agent,
        eventGroup: this.eventGroup
      }
      const config = {}
      let relUrl = '/admin/event-group'
      const url = this.url_composeApiUrl(relUrl)
      console.log('post', postData)
      axios.post(url, postData, config)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onSave()
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
  },
  created() {
    this.getData()
  }
}
</script>
